import { datadogRum } from '@datadog/browser-rum'
import Vue from 'vue'

import { client } from '@maxsystems/client'

import { checkPermission, getUser, setUserHomepage } from '../queries'
import { hasDealerGroupAccess } from '~/shared/dealer-group-access'

export default new Vue({
  data: {
    dealerId: null,
    error: null,
    flHost: process.env.FL_HOST,
    group: null,
    host: process.env.SSO_HOST,
    isThirdPartyCookiesDisabled: true,
    memberId: null,
    permissions: new Map(),
    token: null,
    redirectTimedOut: false,
    loadingMessage: null,
    user: null
  },
  computed: {
    dealer: vm => vm.user?.dealer?.node || {},
    hasError: vm => Boolean(vm.error),
    hasGroupAccess: vm => hasDealerGroupAccess(vm.user, vm.groupDealers),
    loading: vm => Boolean(!vm.user && !vm.hasError),
    name: ({ user }) => user?.profile?.name || user?.username,
    sub: ({ user }) => user?.username,
    groupDealers: vm => {
      const groupId = vm.group?.id || vm.dealer.group.id
      return vm.user?.dealers?.nodes?.filter(dealer => dealer.group?.id === groupId) || []
    }
  },
  methods: {
    reportError (error) {
      // Log error to Datadog
      datadogRum.addError(new Error(error))
    },
    /**
     * Check the `access_token` value from the URL hash. This is set when the user
     * is physically redirect to and from the SSO service. If the token is invalid
     * or has expired the user will be asked to login.
     */
    async checkHash () {
      this.loadingMessage = 'Validating credentials...'
      const token = new URLSearchParams(window.location.hash.slice(1)).get('access_token')
      const {
        memberId,
        dealerId
      } = await this.validateToken(token)

      if (!memberId || !dealerId) return this.login()

      this.setAuthState({ token, memberId, dealerId })

      const urlWithoutHash = `${window.location.pathname}${window.location.search}`
      history.replaceState(null, null, urlWithoutHash)
    },
    install (localVue) {
      localVue.prototype.$auth = localVue.$auth = this
    },
    async setup () {
      // Prevent auth handshake when redirecting to a different dealer
      if (/^\/for\/\d+/.test(location.pathname)) return

      this.initCookiesIframe()

      if (window.location.hash) return this.checkHash()

      this.initAssistedIframe()
    },
    initCookiesIframe () {
      const cookies = document.createElement('iframe')
      cookies.style.display = 'none'
      cookies.src = this.host + '/cookies'
      cookies.id = 'cookies-iframe'
      document.body.appendChild(cookies)

      window.addEventListener('message', ({ origin, data }) => {
        if (!origin.includes(this.host)) return
        if (typeof data === 'string' && data.includes('MAX:3PC')) {
          this.isThirdPartyCookiesDisabled = data === 'MAX:3PC:0'
          if (this.isThirdPartyCookiesDisabled) {
            window.dataLayer.push({ event: 'cookies_disabled' })
          }
          cookies.remove()
        }
      })
    },
    initAssistedIframe () {
      const assisted = document.createElement('iframe')
      assisted.style.display = 'none'
      assisted.src = this.host
      assisted.id = 'assisted-iframe'

      window.addEventListener('message', ({ origin, data }) => {
        if (!origin.includes(this.host)) return
        if (data.error) return this.login()

        const { access_token: token, memberId, dealerId } = data

        if (!token) return
        this.setAuthState({ token, memberId, dealerId })
      })

      document.body.appendChild(assisted)
    },
    login () {
      this.loadingMessage = 'Redirecting to ACV MAX...'

      window.location.replace(this.redirectUrl('login', true))

      setTimeout(() => {
        this.redirectTimedOut = true
        this.loadingMessage = 'ACV MAX is taking a long time to respond. Check your network connection.'
      }, 3000)
    },
    logout () {
      window.location.replace(this.redirectUrl('logout'))
    },
    async setAuthState ({ token, memberId, dealerId }) {
      const breadcrumbCategory = 'auth'

      this.error = null
      this.loadingMessage = 'Logging you in...'

      datadogRum.setGlobalContext({ dealerId, memberId })
      datadogRum.addAction(breadcrumbCategory, { message: `Authenticated user #${memberId} for dealer #${dealerId}` })

      this.token = token
      this.memberId = memberId
      this.dealerId = dealerId

      client.config({ token })

      let isHanging = false

      /* Display a warning if it takes more than 10 seconds to load the user.
       * This is a stopgap measure to ensure that the user is not left waiting
       * for a hanging loading screen. */
      const warningTimeout = setTimeout(() => {
        this.error = {
          message: 'Loading is taking longer than expected. Would you like to try again?',
          label: 'Retry',
          action: () => {
            getUser.abort()
            isHanging = true
            this.setAuthState({ token, memberId, dealerId })

            this.reportError(`${breadcrumbCategory}: The user retried after a timeout on fetching user info`)
          }
        }
      }, 10000)

      const { data, errors } = await getUser.fetch({ id: memberId, dealer: dealerId })

      // If the request is hanging, don't do anything
      if (isHanging) return

      // Clear the warning timeout if query completes
      clearTimeout(warningTimeout)

      if (!data || errors) {
        datadogRum.addAction(breadcrumbCategory, { data: { data, errors } })
        this.error = {
          message: 'An unexpected error has occurred.',
          action: this.login,
          label: 'Try Again'
        }
        this.reportError(`${breadcrumbCategory}:an unexpected error has occurred when getting user info`)
        return
      }

      if (!data?.user?.dealer?.node) {
        datadogRum.addAction(breadcrumbCategory, { data: { data, errors: [new Error('User not authorized to view any dealership information')] } })
        this.error = {
          message: 'You are not authorized to view any dealership information.',
          action: this.logout,
          label: 'Log Out'
        }
        this.reportError(`${breadcrumbCategory}:user is not authorized to view any dealership information`)
        return
      }

      // Resets the timeout error screen if the user is successfully loaded
      this.error = null

      this.user = data?.user
      this.setGroup(this.dealer.group)

      window.dataLayer.push({
        event: 'login',
        userId: memberId,
        username: this.user.username,
        dealer: this.dealer.name,
        businessUnit: {
          groupId: this.dealer.group.id,
          zip: this.dealer.address.postalCode,
          groupName: this.dealer.group.name,
          businessunitcode: this.dealer.internalId.bucode,
          address: this.dealer.address.street,
          businessunitid: dealerId,
          state: this.dealer.address.region,
          dealerName: this.dealer.name,
          city: this.dealer.address.locality
        },
        member: {
          loginId: this.user.username,
          lastName: this.user.profile?.lastName,
          phone: this.user.profile?.phone,
          email: this.user.profile?.email,
          userId: this.user.id,
          fullName: this.user.profile?.name,
          firstName: this.user.profile?.firstName
        }
      })

      // Authentication process for MAX Google Tag Manager in Inventory VDP
      // Prevents a 302 redirect when /fl-ims/secured/tag/gtmInfo is called by MAX
      const fmsHome = document.createElement('iframe')
      fmsHome.style.display = 'none'
      fmsHome.src = this.flHost + '/fl-ims/secured/fmsHome'
      fmsHome.id = 'fms-home'
      document.body.appendChild(fmsHome)

      // Set user context in Datadog
      datadogRum.setUser({
        // Recommended user attributes per https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#user-session
        id: this.user.id,
        name: this.user.profile?.name,
        email: this.user.profile?.email,
        // Additional attributes
        dealerId: dealerId,
        dealerName: this.dealer.name,
        groupId: this.dealer.group.id,
        groupName: this.dealer.group.name
      })

      datadogRum.setGlobalContext({ dealer: this.dealer.name })
    },
    async setGroup ({ id, name }) {
      return await this.$set(this, 'group', { id, name })
    },

    /**
     * Redirect the user to SSO to change their active dealership group.
     * @param {Number} id - Dealer Group ID
     * @param {String} name - Dealer Group name
     * @param {String} targetPath - Where the user should end up after the Dealer group switch
     */
    switchDealerGroup (id, name, targetPath) {
      location.assign(this.redirectUrl(`switch/${id}`, false, targetPath))
    },

    /**
     * Redirect the user to SSO to change their active dealership.
     * @param {Number} buid
     * @param {Object} options - Optional overrides to customize default behaviour.
     * @param {String} options.targetPath - Where the user should end up once redirected
     * back to the application. Defaults to the homepage.
     * @param {Boolean} options.openNewTab - Whether to execute the dealership change in a new tab.
     */
    switchDealer (buid, { targetPath, openNewTab } = {}) {
      const switchDealer = openNewTab
        ? window.open.bind(window)
        : location[targetPath ? 'assign' : 'replace'].bind(window.location)
      switchDealer(this.redirectUrl(`switch/${buid}`, false, targetPath))
    },

    /**
     * Builds an SSO url with a `redirect_uri` parameter containing the current url
     * and optionally, the current query string
     * @param {String} path - targeted SSO action path
     * @param {Boolean} [keepOriginalPath=false] - if false, user is redirected back
     * to the homepage rather than the original path they were on
     * @returns {String} SSO url
     */
    redirectUrl (path, keepOriginalPath = false, targetPath = '') {
      const { origin, pathname = '', search = '' } = window.location
      const url = new URL(this.host)
      url.search = new URLSearchParams({
        redirect_uri: origin + (keepOriginalPath ? pathname + search : targetPath)
      }).toString()
      url.pathname = 'api/' + path
      return url.toString()
    },
    async validateToken (token) {
      const res = await fetch(this.host + '/api/token', {
        credentials: 'include',
        headers: { Authorization: 'Bearer ' + token }
      })

      return res.json()
    },

    async setUserHomepage (homepage) {
      const userId = this.user.id
      const { errors } = await setUserHomepage.fetch({ userId, homepage })

      if (errors?.length) throw new Error(errors[0].message)

      this.user.profile.homepage = homepage
    },

    async fetchPermission (resource, action) {
      const key = `${resource}:${action}`
      this.permissions.pending = this.permissions.pending || new Map()
      if (this.permissions.pending.get(key)) return await this.permissions.pending.get(key)

      this.permissions.pending.set(key, (async () => {
        const { data } = await checkPermission.fetch({
          id: this.memberId,
          resource,
          action
        })

        return Boolean(data?.user?.hasPermission)
      })())

      const hasPermission = await this.permissions.pending.get(key)
      this.permissions.pending.delete(key)

      this.permissions.set(`${resource}:${action}`, hasPermission)
      return hasPermission
    },

    async checkPermission (resource, action) {
      const answer = this.permissions.get(`${resource}:${action}`)
      if (answer === undefined) return await this.fetchPermission(resource, action)
      return answer
    }
  }
})
