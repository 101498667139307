import VueRouter from 'vue-router'

import withRoutes from '../routes'
import { hasNewVersion } from '~/shared/version'

/**
 * Takes an optional state Object and returns an object with `state`,
 * `add`, `get` and `clear` keys for adding, accessing and clearing
 * savedPositions within that state Object
 *
 * @param {Object} state optional state Object to store savedPositions in
 */
export const savedPositionsWithState = (state = {}) => ({
  /** readonly state object */
  get state () { return state },
  /** get stored scroll position by routeName and then clear it  */
  get (routeName) {
    const position = this.state[routeName]
    if (!position) return
    this.clear(routeName)
    return position
  },
  /** store current scroll position by routeName */
  add (routeName) { state[routeName] = { x: 0, y: window.pageYOffset } },
  /** clear stored scroll position by routeName */
  clear (routeName) { delete state[routeName] }
})

export const router = withRoutes(new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== from.name) {
      return router.savedPositions.get(to.name) || savedPosition || { x: 0, y: 0 }
    }
  }
}))

router.savedPositions = savedPositionsWithState()

router.beforeEach((to, from, next) => {
  if (top !== self && to.meta?.layout !== 'none') top.location.replace(top.location.href)
  document.title = [to.meta.title, 'ACV MAX Inventory Management'].filter(Boolean).join(' - ')

  const isFullscreen = to.meta.fullscreen
  document.documentElement.classList[isFullscreen ? 'add' : 'remove']('layout--fullscreen')

  next()
})

router.onError(async ({ message }) => {
  if (
    !message.includes('Unable to preload CSS') &&
    !message.includes('Failed to fetch dynamically imported module') &&
    !message.includes('Failed to load module script')
  ) return
  if (!await hasNewVersion()) return

  window.history.pushState(null, null, router.history.pending.fullPath)
  window.location.reload(true)
})

export default VueRouter
