export default [
  {
    component: () => import(
      /* webpackChunkName: "reports" */
      './ACVOffer.vue'
    ),
    name: 'reports.inventory.offer',
    path: '/reports/inventory/offer'
  }
]
