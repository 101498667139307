export default [
  {
    name: 'enterprise.dashboard',
    path: '/enterprise/dashboard',
    meta: {
      enterprise: true
    },
    component: () => import(
      /* webpackChunkName: "enterprise-dashboard" */
      './Dashboard/index.vue'
    )
  },
  {
    name: 'enterprise.inventory',
    path: '/enterprise/inventory',
    meta: {
      enterprise: true
    },
    component: () => import(
      /* webpackChunkName: "enterprise-inventory" */
      './Inventory/index.vue'
    )
  },
  {
    name: 'enterprise.appraisals',
    path: '/enterprise/appraisals',
    meta: {
      enterprise: true
    },
    component: () => import(
      /* webpackChunkName: "enterprise-appraisals" */
      './Appraisals/index.vue'
    )
  },
  {
    component: () => import(
      /* webpackChunkName: "enterprise-appraisals" */
      './Appraisals/LostCustomers/index.vue'
    ),
    name: 'enterprise.appraisals.lost-customers',
    path: '/enterprise/appraisals/lost-customers',
    meta: {
      enterprise: true
    }
  },
  {
    name: 'enterprise.transfers',
    path: '/enterprise/transfers',
    meta: {
      enterprise: true
    },
    component: () => import(
      /* webpackChunkName: "enterprise-transfers" */
      './Transfers/index.vue'
    )
  }
]
