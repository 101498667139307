<script>
import { VAutocomplete, VSelect } from 'vuetify/lib'

export default VAutocomplete.extend({
  name: 'AutoSizeAutocomplete',
  methods: {
    /**
     * Override the default `VAutocomplete` slot to enable auto-sizing behaviour.
     * Because an `input` tag is used to render the dropdown title the width is always
     * static. Rather than try to calculate the width of the string to auto-size the input
     * we add an inline `span` tag bound to the same text content and use it to control
     * the width of the element.
     */
    genDefaultSlot () {
      const slot = VSelect.options.methods.genDefaultSlot.call(this).filter(Boolean)
      const { children } = slot.find(({ data }) => data.staticClass === 'v-select__slot')
      children.splice(
        children.findIndex(({ tag }) => tag === 'input'), 0,
        this.$createElement('span', this.internalSearch || this.label)
      )
      return slot
    }
  }
})
</script>

<style lang="scss" scoped>
.v-select__slot {
  label + span {
    min-width: 50px;
    opacity: 0;
    padding-right: 12px;
  }

  input {
    position: absolute;
  }
}
</style>
