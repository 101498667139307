import Vue from 'vue'

import available from './features.json'

export const namespace = 'com.max.lilypad.previews'
export const deprecatedFeatures = ['user.settings']

const enabled = Vue.observable({ value: new Set(JSON.parse(localStorage.getItem(namespace) || '[]')) })

export default new Vue({
  data: () => ({
    available,
    overrides: []
  }),
  computed: {
    enabled: {
      get () {
        return new Set([...enabled.value, ...this.overrides])
      },
      set (newValue) {
        enabled.value = new Set(newValue)
        localStorage.setItem(namespace, JSON.stringify(Array.from(enabled.value)))
      }
    }
  },
  created () {
    this.removeDeprecatedFeatures()
  },
  methods: {
    has (feature) {
      return this.enabled.has(feature)
    },
    removeDeprecatedFeatures () {
      deprecatedFeatures.forEach(feature => this.enabled.delete(feature))
      this.enabled = Array.from(this.enabled) // trigger save to localStorage
    }
  }
})
