import Vue from 'vue'
import { VDialog } from 'vuetify/lib'

import escapable from '../mixins/escapable'
import { vuetify } from '~/ui/vue'

/**
 * A low level dialog component that can be used to create temporary dialogs
 * @param {Object} WithComponent - Vue component (template-based or a render method)
 * @param {Object} VDialogOptions - Vuetify VDialog API options
 * @return {Object} - Dialog methods to mount and show the dialog dynamically
 */
export default (WithComponent, VDialogOptions = {}) => {
  const ProgrammaticDialog = Vue.extend({
    name: 'ProgrammaticDialog',
    vuetify,
    mixins: [escapable],
    data: () => ({
      showDialog: true
    }),
    methods: {
      async close (response) {
        this.showDialog = false
        this.resolve(response)
        await this.$nextTick()
        this.$destroy(true)
      },
      onEscPressed () {
        this.close(false)
      }
    },
    render (h) {
      return h(VDialog, {
        on: {
          input: () => this.close(false)
        },
        props: {
          value: this.showDialog,
          width: 500,
          ...VDialogOptions
        }
      }, [
        h(WithComponent, {
          props: this.options,
          on: {
            close: $event => this.close($event)
          }
        })
      ])
    }
  })

  return {
    close (response = false) {
      if (!this.$dialog) return
      this.$dialog.close(response)
      this.$dialog.$destroy()
    },

    open (options) {
      return new Promise(resolve => {
        this.$dialog = new ProgrammaticDialog({
          store: this.$store,
          data: () => ({ options }),
          methods: { resolve }
        })
        this.$dialog.$mount()
      })
    },

    toggle () {
      if (!this.$dialog || this.$dialog._isDestroyed) {
        return this.open()
      }
      this.close()
    }
  }
}
