<script>
export default {
  props: {
    userAgent: {
      type: String,
      default: navigator.userAgent
    },
    supportedBrowsers: {
      type: String,
      default: `${import.meta.env.VITE_APP_SUPPORTED_BROWSERS}`
    }
  },
  data: () => ({
    showDetails: false
  }),
  computed: {
    browser () {
      const ua = this.userAgent
      if (ua.includes('iPad') || ua.includes('iPhone')) return 'ios'
      if (ua.includes('Edge') || ua.includes('Edg')) return 'edge'
      if (ua.includes('Chrome') || ua.includes('Chromium')) return 'chrome'
      return 'other'
    },

    isSupported () {
      const match = this.supportedBrowsers.match('^/(.*?)/([gimy]*)$')
      return this.browser === 'ios' || new RegExp(match[1], match[2]).test(this.userAgent)
    },

    warning () {
      switch (this.browser) {
        case 'chrome':
          return {
            message: 'You are using an old version of Chrome. Some features may not work correctly.',
            link: 'https://www.google.com/chrome/update/'
          }

        case 'edge':
          return {
            message: 'You are using an old version of Edge. Some features may not work correctly.',
            link: 'https://www.microsoft.com/en-us/edge'
          }

        default:
          return {
            message: 'You are using an old or unsupported browser. Some features may not work correctly.',
            link: 'https://www.google.com/chrome/update/'
          }
      }
    }
  }
}
</script>

<template>
  <VBanner
    v-if="!isSupported"
    class="vb"
    color="warning"
    dark
    single-line
    tile
  >
    <template #icon>
      <VIcon
        slot="icon"
        size="24"
        @click="showDetails = !showDetails"
      >
        $error
      </VIcon>
    </template>
    <div
      class="text-wrap"
      @click="showDetails = !showDetails"
    >
      {{ warning.message }}
      <template v-if="showDetails">
        <br>
        <span class="text-caption">
          {{ userAgent }}
        </span>
      </template>
      <VSpacer />
    </div>
    <template #actions>
      <VBtn
        :href="warning.link"
        target="_blank"
        rel="noopener noreferrer"
        text
      >
        Update Now
      </VBtn>
    </template>
  </VBanner>
</template>
