export default ({
  acvUrl,
  buid,
  dealerGroupId,
  firstLookUrl,
  hasFeature,
  hasGroupAccess,
  isIncluded,
  lilypadUrl,
  reportsUrl
}) => [
  {
    condition: isIncluded('dashboard'),
    label: 'Dashboard',
    href: lilypadUrl('/dashboard')
  },
  {
    condition: isIncluded('inventory'),
    label: 'Inventory',
    href: lilypadUrl('/inventory'),
    items: [
      { label: 'MAX Inventory', href: lilypadUrl('/inventory') },
      {
        condition: hasFeature('ims.features.newCarPricing'),
        label: 'New Car Pricing',
        href: firstLookUrl('/merchandising/DiscountPricing/InventoryOverview')
      },
      {
        condition: isIncluded('inventory.merchandising'),
        label: 'Merchandising Dashboard',
        href: firstLookUrl('/merchandising/Dashboard.aspx')
      },
      {
        condition: hasFeature('inTransit'),
        label: 'Add Inventory',
        href: firstLookUrl(
          `/support/Pages/Inventory/InTransitInventory.aspx?bu=${buid}`
        )
      }
    ]
  },
  {
    condition: isIncluded('appraisal'),
    label: 'Appraisal',
    href: lilypadUrl('/appraisals'),
    items: [
      { label: 'All Appraisals', href: lilypadUrl('/appraisals') },
      {
        label: 'Manual Bookout',
        href: firstLookUrl('/IMT/ManualBookoutAction.go')
      },
      {
        label: 'Lost Customers',
        href: lilypadUrl('/appraisals/lost-customers')
      },
      {
        condition: isIncluded('appraisal.makeADeal'),
        label: 'Make a Deal',
        href: firstLookUrl('/fl-ims/secured/appraisalReview')
      },
      {
        label: 'Add Appraisal',
        href: firstLookUrl('/IMT/QuickAppraise.go')
      }
    ]
  },
  {
    condition: isIncluded('stocking'),
    label: 'Stocking',
    items: [
      {
        condition: isIncluded('stocking.auctions'),
        label: 'Auctions',
        href: firstLookUrl('/NextGen/MarketplaceSearchSummary.go')
      },
      {
        condition: isIncluded('stocking.core'),
        label: 'Core Stocking Report',
        href: firstLookUrl('/NextGen/OptimalInventoryStockingReports.go')
      },
      {
        label: 'Market Velocity Stocking Guide',
        href: lilypadUrl('/stocking/mvsg')
      },
      {
        condition: isIncluded('stocking.flSearch'),
        label: 'FirstLook Search',
        href: firstLookUrl('/NextGen/SearchHomePage.go')
      },
      {
        condition: isIncluded('stocking.flashLocator'),
        label: 'Flash Locator',
        href: firstLookUrl('/NextGen/FlashLocateSummary.go')
      },
      {
        condition: isIncluded('stocking.vehicleAnalyzer'),
        label: 'Vehicle Analyzer',
        href: firstLookUrl('/fl-ims/secured/vehicleAnalyzer')
      },
      {
        condition: hasFeature('hasSAMOffers'),
        label: 'S.A.M. Buying Rules',
        href: acvUrl('/myacv/bidmatrix'),
        attrs: { target: '_blank', rel: 'noopener' }
      }
    ]
  },
  {
    condition: isIncluded('reports'),
    label: 'Reports',
    href: reportsUrl(),
    items: [
      {
        condition: isIncluded('reports.all'),
        label: 'All Reports',
        href: reportsUrl()
      },
      {
        label: 'Appraisal Closing Rate Summary',
        href: reportsUrl('/reports/appraisal_closing_rate_summary')
      },
      {
        condition: isIncluded('reports.water'),
        label: 'Water Report',
        href: reportsUrl('/reports/water_report')
      },
      {
        condition: isIncluded('reports.loan'),
        label: 'Loan Value Book Value Calculator',
        href: firstLookUrl('/fl-ims/secured/equityAnalyzer')
      },
      {
        condition: isIncluded('reports.recalls'),
        label: 'Open Recalls',
        href: reportsUrl('/reports/open_recalls')
      },
      {
        condition: isIncluded('reports.pricing'),
        label: 'Pricing List',
        href: reportsUrl('/reports/pricing_list')
      },
      {
        condition: isIncluded('reports.dealLog'),
        label: 'Deal Log',
        href: reportsUrl('/reports/deal_log')
      },
      {
        condition: isIncluded('reports.management'),
        label: 'Management Summary',
        href: firstLookUrl('/IMT/ucbp/TileManagementCenter.go')
      },
      {
        condition: isIncluded('reports.stopDrop'),
        label: 'Stop The Drop',
        href: firstLookUrl('/merchandising/DoubleDiscount/DoubleDiscount')
      },
      {
        condition: isIncluded('reports.notOnline'),
        label: 'Not Online',
        href: firstLookUrl(
          '/merchandising/Reports/VehicleOnlineStatusReport.aspx?usednew=2'
        )
      },
      {
        condition: isIncluded('reports.lowActivity'),
        label: 'Low Activity',
        href: firstLookUrl('/merchandising/Reports/VehicleActivityReport.aspx')
      }
    ]
  },
  {
    label: 'Tools',
    items: [
      {
        label: 'ACV Transport',
        href: acvUrl('/transportation-hub?utm_source=Internal&utm_medium=In-App&utm_campaign=MAX%20In-App%20TXP&utm_id=MAX%20TXP'),
        attrs: { target: '_blank', rel: 'noopener' }
      }
    ]
  },
  {
    condition: hasGroupAccess,
    label: 'Group',
    href: lilypadUrl('/enterprise/dashboard'),
    items: [
      {
        label: 'Group Dashboard',
        href: lilypadUrl('/enterprise/dashboard')
      },
      {
        label: 'Group Inventory',
        href: lilypadUrl('/enterprise/inventory')
      },
      {
        label: 'Group Appraisals',
        href: lilypadUrl('/enterprise/appraisals')
      },
      {
        label: 'Group Reports',
        href: reportsUrl(`/?dealerId=${dealerGroupId}`),
        attrs: { target: '_blank', rel: 'noopener noreferrer' }
      }
    ]
  }
]
