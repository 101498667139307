import { withQuery } from '@maxsystems/client'

import dealerConfigFragment from './dealerConfig.fragment'
import permissionQuery from './permission.gql'
import setUserHomepageQuery from './setUserHomepage.gql'
import userQuery from './user.gql'

export const getUser = withQuery(userQuery + dealerConfigFragment)
export const checkPermission = withQuery(permissionQuery)
export const setUserHomepage = withQuery(setUserHomepageQuery)
