import ToolbarApp from '../components/ToolbarApp.vue'
import UnsupportedBrowser from '../components/UnsupportedBrowser.vue'

export default function (h) {
  return [
    h(UnsupportedBrowser),
    h(ToolbarApp, { props: this.$route.meta }),
    h('router-view'),
    h('portal-target', { props: { name: 'after-footer', multiple: true } })
  ]
}
