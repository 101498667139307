<script>

import DealerAutocomplete from './DealerAutocomplete.vue'
import MenuApp from './MenuApp.vue'
import MenuEnterprise from './MenuEnterprise.vue'
import MenuHelp from './MenuHelp/index.vue'
import MenuUser from './MenuUser.vue'
import Logo from '~/ui/logos/wordmark.vue'

export default {
  name: 'ToolbarApp',
  components: {
    DealerAutocomplete,
    Logo,
    MenuApp,
    MenuHelp,
    MenuUser,
    MenuEnterprise
  },
  props: {
    enterprise: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <VAppBar
    :color="enterprise ? 'dark' : 'primary'"
    class="v-toolbar--app d-print-none"
    dark
    flat
  >
    <router-link
      :to="{ name: 'homepage' }"
      class="logo"
      title="ACV MAX"
    >
      <Logo
        white
        width="120"
      />
    </router-link>
    <MenuEnterprise
      v-if="enterprise"
      class="mx-4"
    />
    <MenuApp
      v-else
      class="mx-4"
    />
    <VSpacer />
    <MenuHelp />
    <DealerAutocomplete />
    <MenuUser />
  </VAppBar>
</template>

<style lang="scss">
.v-toolbar--app {
  @include z-index(app-bar);

  .v-toolbar__content > a {
    display: inherit;
  }

  .v-toolbar__content a.logo {
    height: 100%;
  }
}
</style>
