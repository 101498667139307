export default [
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import(
      /* webpackChunkName: "inventory" */
      './Inventory/index.vue'
    )
  }
]
