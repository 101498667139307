export default [
  {
    component: () => import(
      /* webpackChunkName: "stocking" */
      './MVSG.vue'
    ),
    name: 'stocking.mvsg',
    path: '/stocking/mvsg'
  }
]
