import Component from './Dialog.vue'
import { ProgrammaticDialog } from '~/ui/elements'

const Dialog = ProgrammaticDialog(Component)

document.addEventListener('keydown', $event => {
  if ($event.key !== '`') return
  Dialog.toggle()
})

export { Component }
export default Dialog
