export const versioning = new Map()

export const getAppVersion = () => {
  const headers = new Headers()
  headers.append('cache-control', 'no-cache')
  headers.append('pragma', 'no-cache')
  return fetch(`${window.location.origin}/v.json`, { headers }).then(res => res.json())
}

export const init = async () => Object.entries(await getAppVersion()).forEach(([chunk, version]) => versioning.set(chunk, version))

export const hasNewVersion = async () => {
  if (!versioning.has('release')) {
    await init()
    return false
  }

  const { release } = await getAppVersion()
  return (versioning.get('release') !== release)
}
