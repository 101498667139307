/**
 * Returns a URL object that points to the firstlook app considering the given path and environment.
 *
 * @param {string} [path=''] - The path to append to the URL.
 * @param {string} [env=process.env.DEPLOY_ENV] - The environment to use for the URL prefix.
 * @returns {URL} - The URL for the firstlook app.
 */
export const getFirstlookUrl = (path = '', env = process.env.DEPLOY_ENV) => {
  const prefix = (env === 'stage' || env === 'dev') ? env : ''
  return new URL(path, `https://max${prefix}.firstlook.biz`)
}

/**
 * Returns a URL object that points to the firstlook reports app considering the given path and environment.
 *
 * @param {string} [path=''] - The path to append to the URL.
 * @param {string} [env=process.env.DEPLOY_ENV] - The environment to use for the URL prefix.
 * @returns {URL} - The URL for the firstlook reports app.
 */
export const getFirstlookReportsUrl = (path = '', env = process.env.DEPLOY_ENV) => {
  const url = getFirstlookUrl(path, env)
  url.hostname = 'reports.' + url.hostname
  return url
}

export const firstlookHostname = getFirstlookUrl().hostname
