import Vuetify from 'vuetify/lib'

import { tokens } from '../'
import * as values from '../icons/mdi'
import * as filters from './filters'
import { injectIcons } from './injectIcons'

export const minifyTheme = css => css
  .replace(/\n\s?|\s(?=[\w!#\-{])/g, '')
  .replace(/\s{2,}/g, ' ')

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values
  },
  theme: {
    themes: { light: tokens.colors },
    options: {
      customProperties: true,
      minifyTheme
    }
  }
})

export const framework = vuetify.framework

export default {
  install (Vue, config = {}) {
    framework.icons.values = {
      ...framework.icons.values,
      ...config.icons
    }
    Vue.use(Vuetify)
    Object.keys(filters).forEach(filter => {
      if (typeof filters[filter] !== 'function') return
      Vue.filter(filter, filters[filter])
    })
  }
}

export const addIcons = injectIcons(framework)
