<script>
import AutoSizeAutocomplete from './AutoSizeAutocomplete.vue'
import storable from '~/mixins/storable'

export default {
  components: {
    AutoSizeAutocomplete
  },
  mixins: [storable('recent', 'dealers.recent')],
  inject: ['features'],
  data: () => ({
    recentMax: 5,
    loading: true,
    value: null,
    recent: []
  }),
  computed: {
    dealerNodes: ({ $auth }) => $auth.user?.dealers?.nodes || [],
    dealers ({ dealerNodes, groups }) {
      return dealerNodes.map(({ id, name }) => ({ id, name }))
        .concat(groups)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },
    groups: ({ dealerNodes }) => Object.values(dealerNodes.reduce((groups, { group }) => {
      if (group) {
        // start counting how many dealerships are in the group
        groups[group.id] ??= { count: 0, group }
        groups[group.id].count++
      }
      return groups
    }, {}))
      // filter out any group with less than 2 dealerships
      .filter(({ count }) => count > 1)
      // add an `isGroup` flag to help identify groups in the dealers list
      .map(({ group: { id, name } }) => ({ id, name, isGroup: true })),

    items () {
      if (!this.recent.length) return this.dealers
      return [
        { header: 'Recent' },
        ...this.recent,
        { divider: true },
        { header: 'All' },
        ...this.dealers
      ]
    }
  },
  watch: {
    '$route.meta.enterprise' () {
      this.setRecent()
    }
  },

  mounted () {
    this.setRecent()
    this.loading = false
  },
  methods: {
    setRecent () {
      const isGroup = this.$route.meta.enterprise
      this.value = isGroup
        ? {
            id: this.$auth.group.id,
            name: this.$auth.group.name,
            isGroup: true
          }
        : this.recent.filter(
          ({ id }) => id === String(this.$auth.dealer.id)
        )[0] || this.$auth.dealer

      this.saveRecent(this.value)
    },
    saveRecent ({ id, name, isGroup }) {
      if (this.dealers.length <= this.recentMax) return

      // remove the dealer if it already exists in recent
      this.recent = this.recent.filter(r => r.id !== id)

      // add the new dealer to the top
      this.recent.unshift({ id, name, isGroup })

      // trim the length to max recent length
      this.recent = this.recent.slice(0, this.recentMax)
    },
    /**
     * Switches the dealer or group based on the selected item
     * @param {Object|null} dealerData - information defined by setRecent
     * @returns {undefined|void} - returns early when cleaning up the input
     *                             or void by switching the dealer or group
     */
    selectDealer (dealerData) {
      if (dealerData === null) return
      const { id, name, isGroup } = dealerData
      this.saveRecent({ id, name, isGroup })
      if (!isGroup) return this.$auth.switchDealer(id, { targetPath: '/dashboard' })

      this.$auth.switchDealerGroup(id, name, '/enterprise/dashboard')
    }
  }
}
</script>

<template>
  <AutoSizeAutocomplete
    :items="items"
    :menu-props="{
      contentClass: 'v-autocomplete--dealer__menu'
    }"
    :loading="loading && 'white'"
    :value="value"
    class="v-autocomplete--dealer"
    :return-object="true"
    item-text="name"
    label="Dealership"
    dark
    dense
    hide-details
    outlined
    @change="selectDealer"
  >
    <template #item="{ item }">
      <template v-if="(typeof item !== 'object')">
        <VListItemContent v-text="item" />
      </template>
      <template v-else>
        <VListItemContent>
          <VListItemTitle v-text="item.name" />
        </VListItemContent>
        <VListItemAction v-if="item.isGroup">
          <VChip
            :input-value="true"
            label
            x-small
            v-text="'group'"
          />
        </VListItemAction>
      </template>
    </template>
  </AutoSizeAutocomplete>
</template>

<style lang="scss">
.v-autocomplete--dealer {
  flex-grow: 0;

  &.v-select--is-menu-active .v-select__slot label + span {
    min-width: 250px;
  }

  .v-input__icon {
    cursor: pointer;
  }

  &__menu {
    .v-chip {
      cursor: pointer;
    }
  }
}
</style>
