import {
  VBtn,
  VDivider,
  VContainer
} from 'vuetify/lib'

import NetworkCheck from '../components/NetworkCheck.vue'
import { ProgrammaticDialog } from '~/ui/elements'
import Logo from '~/ui/logos/wordmark.vue'
const NetworkDialog = ProgrammaticDialog(NetworkCheck)

export default function (h) {
  return [
    h(VContainer, {
      class: ['justify-center', 'flex-column', 'fill-height']
    }, [
      h(Logo, {
        props: {
          flat: true,
          color: 'primary',
          width: 80
        }
      }),
      [
        h('span', {
          class: 'ma-3 error--text'
        }, this.$auth.error.message),
        h('div', {
          class: 'd-flex justify-center'
        }, [
          h(VBtn, {
            on: {
              click: () => this.$auth.error.action()
            },
            props: {
              color: 'primary',
              depressed: true,
              small: true
            }
          }, this.$auth.error.label),
          h(VDivider, {
            class: 'mx-3',
            props: {
              vertical: true
            }
          }),
          h('button', {
            class: 'caption',
            on: {
              click: () => NetworkDialog.open()
            }
          }, 'Still facing issues? Check your network')
        ])
      ]
    ])
  ]
}
