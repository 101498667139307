<script>
import Vue from 'vue'
import {
  VApp,
  VMain,
  VSnackbar
} from 'vuetify/lib'

import ToolbarApp from './components/ToolbarApp.vue'
import UnsupportedBrowser from './components/UnsupportedBrowser.vue'
import * as layouts from './layouts'

export default {
  name: 'LilypadApp',
  components: {
    UnsupportedBrowser,
    ToolbarApp
  },
  data: () => ({
    snackMessage: null
  }),
  computed: {
    layout () {
      if (this.$auth.loading) return layouts.loading
      if (this.$auth.hasError) return layouts.error

      // In some cases we want routes to be rendered without the default layout.
      // An example of this is the embeddable pricing tab.
      if (this.$route.meta?.layout === 'none') return layouts.embed

      return layouts.default
    }
  },
  created () {
    Vue.prototype.$showNotification = this.showNotification
  },
  methods: {
    showNotification (text) {
      this.snackMessage = text
    }
  },
  render (h) {
    return h(VApp, [
      h(VMain, this.layout(h)),
      h(VSnackbar, {
        props: {
          value: Boolean(this.snackMessage),
          timeout: 4000
        },
        on: { input: () => { this.snackMessage = null } }
      }, this.snackMessage)
    ])
  }
}
</script>

<style lang="scss">
@import "~/ui/styles/app";

html.layout--fullscreen {
  overflow-y: hidden;
}

.v-main__wrap > .vue-portal-target > * {
  @include z-index(dialog);
}

// Updates to the VTooltip global styling based on Material specs.
// @see https://material.io/components/tooltips#specs
.v-application.theme--light .v-tooltip__content {
  font-size: 10px;
  line-height: 24px;
  padding: 0 8px;
}

// Rounded treatment for Segmented toggle buttons.
.v-btn-toggle--segmented--rounded {
  background: map-get($material-light, chips) !important;
  border: 2px solid map-get($material-light, chips) !important;
  border-radius: 16px !important;

  .v-btn-toggle__overlay {
    border-radius: inherit;
  }
}

[aria-busy="true"],
.v-application a[aria-busy="true"] {
  cursor: progress;
  pointer-events: all;
}
</style>
