<script>
import features from '~/plugins/features'

export default {
  name: 'FeaturePreviewDialog',
  data: () => ({
    features: features.available,
    overrides: features.overrides
  }),
  computed: {
    available () {
      return Object.keys(this.features)
    },
    enabled: {
      get () {
        return this.available.reduce((result, feature, i) => {
          if (features.enabled.has(feature)) {
            result.push(i)
          }
          return result
        }, [])
      },
      set (newValue) {
        features.enabled = newValue.map(index => this.available[index])
      }
    }
  }
}
</script>

<template>
  <VCard>
    <VCardTitle class="headline">
      Feature preview
      <VSpacer />
      <VBtn
        icon
        @click="$emit('close', true)"
      >
        <VIcon>$close</VIcon>
      </VBtn>
    </VCardTitle>
    <VDivider />
    <VList
      flat
      two-line
      subheader
    >
      <VListItemGroup
        v-model="enabled"
        multiple
      >
        <VListItem
          v-for="(feature, key) in features"
          :key="key"
          class="pl-6 pr-7"
        >
          <template #default="{ active }">
            <VListItemContent>
              <VListItemTitle>{{ feature.title }}</VListItemTitle>
              <VListItemSubtitle>
                {{ feature.subtitle }}
              </VListItemSubtitle>
            </VListItemContent>

            <VListItemAction>
              <VSwitch
                :input-value="active"
                :disabled="overrides.includes(key)"
                inset
              />
            </VListItemAction>
          </template>
        </VListItem>
      </VListItemGroup>
    </VList>
  </VCard>
</template>
