export default `
  fragment dealerConfig on Dealer {
    config {
      hasSAMOffers:hasFeature(product: "ims", feature: "hasSAMOffers")
      hasCrmOptedIn:hasFeature(product: "ims", feature: "CrmOptedIn")
      inTransit:hasFeature(product: "ims", feature: "inTransit")
      hasRequestACVInspection:hasFeature(product: "ims", feature: "RequestACVInspection")
      ims {
        features {
          newCarPricing
        }
      }
    }
  }
`
