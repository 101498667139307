export default ({ name, path, meta } = {}) => ({
  name: name + '.guide',
  path: path + '/guide',
  meta,
  component: () => import(
    /* webpackChunkName: "photos.guide" */
    './index.vue'
  ),
  children: [
    {
      name: name + '.guide.feed',
      path: ':guide',
      meta,
      component: () => import(
        /* webpackChunkName: "photos.guide.feed" */
        './Feed/index.vue'
      )
    },
    {
      name: name + '.guide.settings',
      path: ':guide/settings',
      meta,
      component: () => import(
        /* webpackChunkName: "photos.guide.settings" */
        './Settings/index.vue'
      )
    }
  ]
})
