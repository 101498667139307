import './plugins/datadog'

import { datadogRum } from '@datadog/browser-rum'
import PortalVue from 'portal-vue'
import Vue from 'vue'

import { client } from '@maxsystems/client'

import { name, version } from '../package.json'
import App from './App.vue'
import Auth from './plugins/auth'
import features from './plugins/features'
import * as icons from './plugins/icons'
import VueRouter, { router } from './plugins/router'
import * as versioning from './shared/version'
import UI, { vuetify } from '~/ui/vue'

// set current release version in order to be able to determine if app was updated
// whenever we get a ChunkLoadError
versioning.init()

client.config({ name, version })

Vue.use(PortalVue)
Vue.use(Auth)
Vue.use(UI, { icons })
Vue.use(VueRouter)

Vue.config.errorHandler = error => {
  if (process.env.NODE_ENV === 'production') {
    datadogRum.addError(error)
    return
  }
  console.error(error)
}

export default new Vue({
  provide: () => ({ features }),
  router,
  created () {
    this.$auth.setup()
  },
  render: h => h(App),
  vuetify
}).$mount('#app')
