const KEY = 0
const VALUE = 1

export default (source = null, storageKey = null) => ({
  props: {
    storable: {
      type: Object,
      default: () => ({ source, storageKey })
    }
  },
  computed: {
    storableData () {
      return [
        `com.max.lilypad.${this.storable.storageKey}.${this.$auth.sub}`,
        JSON.stringify(this[this.storable.source])
      ]
    }
  },
  watch: {
    storableData: {
      handler (newStorableData, oldStorableData) {
        if (newStorableData?.[KEY] !== oldStorableData?.[KEY]) this.loadStorable()

        if (oldStorableData?.[VALUE] === undefined) return // skip initial (immediate) call
        if (newStorableData?.[VALUE] === oldStorableData?.[VALUE]) return
        localStorage.setItem(...newStorableData)
      },
      immediate: true
    }
  },
  methods: {
    loadStorable () {
      const storedValue = JSON.parse(localStorage.getItem(this.storableData[KEY]))
      if (storedValue === null) return
      this[this.storable.source] = storedValue
    }
  }
})
